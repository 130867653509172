.main-left {
  @include up-sm {
    padding-left: 80px;
  }
}

.header-left {
  width: 80px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: $px-bg-3;
  border-right: 1px solid rgba($px-dark, 0.1);
  .theme-light & {
    background: $px-white;
  }
  .scroll-bar {
    height: 100%;
    @include down-sm {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .hl-top {
    a {
      background: $px-theme;
      color: $px-white;
      font-weight: 600;
      text-decoration: none;
      font-size: 40px;
      height: 80px;
      width: 80px;
      line-height: 80px;
      display: inline-block;
      vertical-align: top;
      text-align: center;
    }
  }
  .nav-menu {
    padding-top: 45px;
    padding-bottom: 45px;
    li {
      width: 100%;
      + li {
        border-top: 1px solid rgba($px-white, 0.1);
        .theme-light & {
          border-top: 1px solid rgba($px-dark, 0.1);
        }
      }
      .nav-link {
        margin: 0;
        padding: 10px 0;
        color: $px-white;
        text-decoration: none;
        @include transition(ease all 0.35s);
        text-align: center;
        font-size: 27px;
        .theme-light & {
          color: #333;
        }
        i {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 25px;
        }
      }
      @include up-sm {
        &:hover {
          color: $px-theme;
        }
      }
      &.active {
        .nav-link,
        span {
          color: $px-theme;
        }
      }
    }
  }
  &.header-menu-style-two {
    .nav-menu li {
      .nav-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 13px 0 10px;
        &::after {
          content: "";
          position: absolute;
          top: auto;
          left: 0;
          width: 2px;
          height: 0;
          top: auto;
          bottom: 0;
          background: #ff9301;
          transition: 0.3s;
        }

        .item {
          font-size: 15px;
          display: block;
          padding-top: 3px;
        }
      }
      &.active {
        .nav-link {
          font-weight: normal;
          &::after {
            height: 100%;
            bottom: auto;
            top: 0;
          }
        }
      }
    }
  }
  .social-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80px;
    display: none;
    a {
      color: $px-white;
      font-size: 14px;
      margin-right: 17px;
      &:hover {
        color: $px-theme;
      }
    }
  }
}

.tooltip {
  font-size: 12px;
}

.mob-header {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 11;
  > .d-flex {
    align-items: center;
  }
  .navbar-brand {
    padding: 0;
  }
  .logo-text {
    font-weight: 600;
    color: $px-white;
    font-size: 25px;
    line-height: 1;
    .theme-light & {
      color: $px-dark;
    }
  }
  .fixed-header & {
    background: $px-dark;
  }
  .theme-light.fixed-header & {
    background: $px-white;
  }
}

.toggler-menu {
  width: 60px;
  height: 60px;
  position: relative;
  border-radius: 50%;
  padding: 0;
  background: $px-theme;
  border: none;
  cursor: pointer;
  margin-left: auto;
  span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 25px;
    height: 2px;
    margin: auto;
    background: $px-white;
    color: $px-white;
    transition: 0.3s;
  }
}

@include down-sm {
  .mob-header {
    display: block;
  }
  .header-left {
    @include transition(ease all 0.35s);
    transform: translateX(-100%);
    z-index: 222;
    &.menu-open {
      transform: translateX(0);
    }
  }
}

.toggler-menu span {
  transition: 0.3s;
}
.toggler-menu span:nth-child(1) {
  top: -17px;
}
.toggler-menu span:nth-child(2) {
  top: 2px;
}
.toggler-menu span:nth-child(3) {
  top: auto;
  bottom: 19px;
}
.toggler-menu .active span:nth-child(1) {
  top: 0px;
  transform: rotate(45deg);
}
.toggler-menu .active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.toggler-menu .active span:nth-child(3) {
  top: 18px;
  transform: rotate(-45deg);
}

.bounce-arrow {
  font-size: 2rem;
  /* Adjust size as needed */
  position: absolute;
  bottom: 20px;
  left: 10%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  cursor: pointer;
  color: #5600C0;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-50%) translateY(0);
  }

  40% {
    transform: translateX(-50%) translateY(-30px);
  }

  60% {
    transform: translateX(-50%) translateY(-15px);
  }
}
